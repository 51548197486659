@import 'styles/typography/_mixins';
@import 'styles/_foundation';
@import 'styles/_variables';

$slider-arrow-size: 36px;
$slider-dot-size: 16px;
$slider-content-min-height: 177px;

.empty-shopping-list-slider {
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
    width: 75%;
    margin: auto;
    min-height: 74px;

    h2 {
      @include df-h3-medium;
      color: $black;
    }

    p {
      @include df-p2-medium;
      color: $light-gray-text;
    }
  }

  &__guide-tiles {
    padding: $s6 0 $s6 10px;
    min-height: $slider-content-min-height;
    border-top: 1px solid $input-gray;

    .grid-x {
      align-items: center;
    }

    &__icon {
      margin-right: $s2;

      img {
        margin: 0;
        width: 61px;
        height: 61px;
      }
    }

    &__description {
      @include df-p2-medium;
      color: $black;
    }
  }

  &__button {
    padding: 0 $s2 $s2;

    .cor-button {
      width: 100%;
    }
  }

  .swiper {
    &-button-next,
    &-button-prev {
      top: 32px;
      height: $slider-arrow-size;
      width: $slider-arrow-size;
      background: url('~assets/images/icons/icon-recent-orders-slider-arrow.svg') $white no-repeat;
      background-size: contain;

      &:after {
        display: none;
      }
    }

    &-button-prev {
      z-index: $dropdown-z-index;
    }

    &-button-next {
      top: 50px;
      transform: translate(0, -50%) rotate(180deg);
    }

    &-pagination {
      position: static;
      text-align: start;
      padding-left: $s2;

      &-horizontal {
        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            width: $slider-dot-size;
            height: $slider-dot-size;
            border: 2px solid $red;
            background-color: transparent;
            border-radius: 50%;
            opacity: 1;
            margin: 0 2px;

            &-active {
              background-color: $red;
            }
          }
        }
      }
    }
  }
}

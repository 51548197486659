@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.payment-summary-dropdown {
  $payment-summary-dropdown: &;

  width: 100%;
  height: 48px;
  position: relative;
  display: inline-block;
  margin-top: $s2-5;

  @include breakpoint(medium) {
    width: 300px;
    margin: 0;
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    color: $black;
    border: 1px solid $input-gray;
    border-radius: 8px;
    cursor: pointer;
    height: 100%;

    &-value {
      @include df-p1-medium;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px;

      @include breakpoint(medium) {
        width: 259px;
      }

      &--placeholder {
        font-weight: 700;
        letter-spacing: 0.2px;
      }
    }

    &-arrow {
      width: 45px;
      height: 100%;
      border-left: 1px solid $input-gray;

      &-icon {
        height: 16px;
        width: 16px;
        margin: $s2 $s2 $s2 13px;
      }
    }
  }

  &__card-type {
    display: flex;
    justify-content: flex-start;
    margin-top: 2px;

    &-img {
      width: 29px;
      height: 20px;
      margin-right: 5px;
    }

    &-name {
      margin-bottom: 2px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &__card-number {
    margin: 2px 0 0 0;
  }

  &__label {
    @include df-p3-medium;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 16px;
    padding: $s1-5 0 0 $s1-5;
    height: 40px;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 9px 0 0 0;
    padding-top: $s1;
    background-color: $white;
    border-radius: 4px;
    list-style-type: none;
    z-index: $dropdown-z-index;
    box-shadow: 0 2px 8px 0 #0000001f;
    overflow-y: auto;
    max-height: 21em;

    @include breakpoint(medium) {
      padding-bottom: $s1;
    }
  }

  &__card,
  &__account {
    @include df-p1-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: $s1-5;
    height: 48px;

    &:hover {
      background-color: $light-coral;
    }
  }

  &__account-type {
    text-transform: lowercase;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__add-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    padding: $s1-5;
    width: 100%;
    cursor: pointer;

    &-button {
      @include df-p1-medium;
      text-align: left;
      width: 100%;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &:hover {
      background-color: $light-coral;
    }
  }

  &--with-error {
    #{$payment-summary-dropdown}__select {
      border: 1px solid $red;
    }

    #{$payment-summary-dropdown}__error-message {
      @include df-p2-italic;
      color: $red;
      padding-top: $s1;
    }
  }
}

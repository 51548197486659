@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.discontinued-with-replacement-slider {
  $slider-arrow-size: 30px;

  &.swiper {
    outline: none;
    max-width: 100%;
  }

  .swiper {
    &-pagination {
      &.swiper-pagination-horizontal {
        margin-top: $s6;
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(medium) {
          margin: $s4 0 $s3 0;
        }
      }

      &-horizontal {
        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            width: 22px;
            height: 22px;
            border: 1px solid $input-gray;
            border-radius: 50%;
            margin: 0 $s0-5;
            background: $white;
            opacity: 1;

            &-active {
              background: $orange;
              border: 1px solid $orange;
            }
          }
        }
      }
    }

    &-button-next,
    &-button-prev,
    &-button-next:focus,
    &-button-prev:focus {
      height: $slider-arrow-size;
      width: $slider-arrow-size;
      border-radius: 50%;
      border: 1px solid $orange;
      outline: none;
      position: absolute;
      top: 91%;

      @include breakpoint(small down) {
        display: none;
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 9px;
        left: 14px;
        border: solid $orange;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: $s0-5;
        transform: rotate(-45deg) translate(-50%, -50%);
      }

      &:after {
        display: none;
      }

      &:hover {
        background: $orange;

        &:before {
          border-color: $white;
        }
      }
    }

    &-button-prev {
      margin-right: $s4;
      right: 60%;
      left: auto;
      transform: rotate(180deg);

      &.button-reset {
        border: 1px solid $orange;
      }
    }

    &-button-next {
      margin-left: $s4;
      left: 60%;
      &.button-reset {
        border: 1px solid $orange;
      }
    }
  }

  .product-replacement-line-item-buttons__request-quote-btn {
    .custom-tooltip {
      .custom-tooltip__content {
        @include df-p2-medium;
        width: 100%;
        margin-left: -95px;

        &:after {
          right: 4px;
          left: auto;
        }

        @include breakpoint(medium) {
          margin-left: -99px;
        }
      }
    }
  }
}

@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$iconSize: 24px;
$multiCheckboxSize: 18px;

.select {
  $selectPrefix: '.select';
  #{$selectPrefix}__control {
    min-height: 40px;
    border-radius: 8px;
    border-color: $input-gray;
    font-size: 14px;
    line-height: 1.71;
    padding-left: 10px;
    box-shadow: none;
    display: flex;
    &:hover,
    &--is-focused {
      border-color: $dark-gray;

      #{$selectPrefix}__indicator-separator {
        background-color: $dark-gray;
      }
    }

    #{$selectPrefix} {
      &__indicator-separator {
        background-color: $input-gray;
        margin: 0;
      }

      &__label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      &__icon {
        width: $iconSize;
        height: $iconSize;
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: 0;
        img {
          width: 100%;
          height: auto;
        }
      }

      &__dropdown-icon {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid $red;
        transition: all 0.3s ease;
      }

      &__indicators {
        width: 40px;
      }

      &__indicator {
        width: 100%;
        justify-content: center;
        flex: 1;
      }

      &__value-container {
        padding: 2px 10px 2px 0;

        &--is-multi .select__value-container--is-multi {
          display: none;

          &:first-child {
            display: inline-block;
          }
        }
      }

      &__placeholder,
      &__single-value {
        top: 58%;
      }

      &__single-value {
        color: $black;
      }
    }

    &--is-disabled {
      border-color: $light-gray;
      background: $snow;

      #{$selectPrefix}__indicator-separator {
        background: $input-gray;
      }

      #{$selectPrefix}__dropdown-icon {
        border-top-color: $input-gray;
      }
    }

    &--menu-is-open {
      #{$selectPrefix}__dropdown-icon {
        transform: rotate(180deg);
      }
    }
  }

  &--is-error {
    #{$selectPrefix}__control {
      border-color: $red;

      &:hover {
        border-color: $red;

        #{$selectPrefix}__indicator-separator {
          background-color: $red;
        }
      }

      #{$selectPrefix}__indicator-separator {
        background-color: $red;

        &:hover {
          border-color: $red;
        }
      }
    }

    &-message {
      @include df-p2-italic;
      color: $red;
      margin-top: $s1;

      @include breakpoint(medium) {
        margin-bottom: 14px;
      }
    }
  }

  #{$selectPrefix}__menu {
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

    #{$selectPrefix} {
      &__option {
        font-size: 14px;
        line-height: 1.43;
        padding: $s1-5;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: $black;

        &:hover {
          background: $light-coral;
        }

        &--is-focused {
          background: $light-coral;

          &:not(:hover) {
            background: $light-coral;
          }
        }

        &--is-selected {
          background: transparent;
          color: $aubergine-gray;
        }
      }
    }
  }

  #{$selectPrefix}__menu-list {
    padding: 0;
  }

  &--multiple-values {
    .locations-dropdown {
      &__item {
        display: flex;
        align-items: center;

        &--deleted {
          display: flex;
          align-items: center;
          color: $red;
          font-style: italic;
        }

        &:before,
        &--deleted:before {
          content: '';
          display: inline-block;
          margin-right: $s2;
          width: $multiCheckboxSize;
          min-width: $multiCheckboxSize;
          height: $multiCheckboxSize;
          box-sizing: border-box;
          border: 1px solid $aubergine-gray;
          border-radius: 4px;
          background-image: url('../../assets/images/icons/icon-checkmark.svg');
        }

        &:hover:before {
          border: 2px solid $aubergine-gray;
        }
      }
    }

    .locations.select__option--is-selected {
      .locations-dropdown__item:before,
      .locations-dropdown__item--deleted:before {
        background-color: $aubergine-gray;
        border-color: $aubergine-gray;
        border: 1px solid $aubergine-gray;
      }
    }
  }
}

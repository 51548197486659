@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$slider-arrow-size: 36px;
$slider-dot-size: 16px;

.recent-orders-slider {
  padding-bottom: $s2;
  position: relative;

  &__info {
    display: flex;
    margin: 0 auto;
    padding: $s2 0 $s3 0;
    justify-content: center;
    border-top: 1px solid $input-gray;

    &-block {
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-top: $s2;
      }

      &-label,
      &-value {
        display: block;
      }

      &-label {
        @include df-caption;
        font-size: 11px;
        color: $dark-gray;
        margin-bottom: $s1;
      }

      &-value {
        @include df-s1;
        text-transform: none;
        color: $black;
      }
    }
  }

  &__button {
    height: 40px;

    &:last-child {
      margin-top: $s2;
    }
  }

  &__counter {
    padding: $s2-5 0;
    font: 18px/1.33 $museo-500;
    letter-spacing: 0.4px;
    text-align: center;
    color: $black;
  }

  &__see-all {
    display: flex;
  }

  &__content {
    margin-bottom: $s2;

    &--single-order {
      margin-bottom: $s4;
    }
  }

  .swiper {
    &-button-next,
    &-button-prev {
      top: 32px;
      height: $slider-arrow-size;
      width: $slider-arrow-size;
      background: url('~assets/images/icons/icon-recent-orders-slider-arrow.svg') $white no-repeat;
      background-size: contain;

      &:after {
        display: none;
      }
    }

    &-button-prev {
      z-index: $dropdown-z-index;
    }

    &-button-next {
      top: 50px;
      transform: translate(0, -50%) rotate(180deg);
    }

    &-pagination {
      display: flex;
      position: static;
      text-align: start;
      padding-left: $s2;
      margin-bottom: $s2;

      &-horizontal {
        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            width: $slider-dot-size;
            height: $slider-dot-size;
            border: 2px solid $red;
            background-color: transparent;
            border-radius: 50%;
            opacity: 1;
            margin: 0 2px;

            &-active {
              background-color: $red;
            }
          }
        }
      }
    }
  }
}

@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$slider-arrow-size: 40px;
$slider-dot-size: 16px;

.shopping-list-slider {
  $shopping-list-slider: &;

  position: relative;
  padding-bottom: $s2;

  &__list-info {
    display: flex;
    padding: $s2 0 $s3 0;
    justify-content: center;
    border-top: 1px solid $input-gray;

    & > div {
      width: 90%;
    }
  }

  &__list-block-label {
    @include df-caption;
    font-size: 11px;
    color: $dark-gray;
    margin-bottom: $s1;
  }

  &__list-block-value {
    @include df-h3-bold;
  }

  &__list-name {
    margin-bottom: $s2;
  }

  &__add-all-to-cart {
    margin-bottom: $s2;
  }

  .swiper {
    position: relative;

    &-button-prev,
    &-button-next {
      position: absolute;
      top: 35px;
      height: $slider-arrow-size;
      width: $slider-arrow-size;
      background: url('~assets/images/icons/icon-recent-orders-slider-arrow.svg') $white no-repeat;
      background-size: contain;
      border-radius: 50%;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    &-button-prev {
      left: 16px;
      z-index: $dropdown-z-index;
    }

    &-button-next {
      transform: translate(0, -50%) rotate(180deg);
      top: 55px;
      right: 16px;
    }

    &-slide {
      height: auto;

      > div {
        height: 100%;
      }
    }

    &-pagination {
      display: flex;
      justify-content: flex-start;
      width: fit-content;

      &-bullets {
        margin: $s2;
        bottom: 0;
      }

      &-bullet {
        height: $slider-dot-size;
        width: $slider-dot-size;
        border: 2px solid $red;
        background: $white;

        &-active {
          background: $red;
        }

        &:not(.swiper-pagination-bullet-active) {
          opacity: 1;
        }
      }
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin-right: 0;
  }

  &__counter {
    padding: $s2-5 0;
    font: 18px/1.33 $museo-500;
    letter-spacing: 0.4px;
    text-align: center;
    color: $black;
  }

  &#{&} .shopping-list-slider__content {
    height: fit-content;
    margin-bottom: $s2;
    display: flex;
    flex-direction: column;
  }

  &--single-list {
    margin-bottom: $s4;

    #{$shopping-list-slider}__buttons {
      padding-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin: auto 0 $s2 0;
    padding-bottom: 17px;
    flex-direction: column;
  }
}

@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.manage-quote-modal {
  color: $black;

  &__title {
    @include df-h2;
    color: $black;
  }

  &__customers-message {
    @include df-p2-medium;
    word-break: break-all;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__message-title,
  &__info-label {
    @include df-caption;
    color: $dark-gray;
    display: block;
  }

  &__comment {
    display: flex;
    flex-direction: column;
    margin-bottom: $s4;

    .error-message-hint {
      margin-bottom: $s2-5;
    }

    &-label {
      margin-bottom: $s1;
    }

    &-input {
      @include df-p2-medium;
      border: 1px solid $input-gray;
      border-radius: $s0-5;
      resize: none;
      padding: $s1-5 $s2;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }

    &-label {
      margin-bottom: $s1;

      &.info-label-valid-until,
      &.info-label-price {
        display: flex;
        align-items: center;

        .custom-tooltip {
          margin-left: $s0-5;
          margin-top: -6px;
        }
      }
    }

    &-input {
      max-width: 140px;
    }

    &-block {
      @include breakpoint(small down) {
        width: 50%;
      }

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: $s2 0;

        @include breakpoint(medium) {
          width: initial;
          margin: 0;
        }
      }
    }

    &-value {
      .text-field__input {
        padding: 7px $s2;
      }
    }
  }

  &__approve-button {
    @include breakpoint(small down) {
      width: 100%;
    }
  }

  &__button {
    margin: 0 0 $s1-5 0;

    @include breakpoint(small down) {
      width: 100%;
    }

    @include breakpoint(medium) {
      margin: 0 $s1;
    }

    @include breakpoint(1200px) {
      margin: 0 $s4;
    }
  }

  &__estimated-delivery {
    min-width: 230px;

    @include breakpoint(small down) {
      width: 100%;
    }

    .select-field .select-field__control {
      padding-left: 37px;
      background: url('../../../assets/images/icons/icon-calendar.svg') 9px 7px no-repeat;
      background-size: 24px;

      &.select-field__control--is-disabled {
        background: url('../../../assets/images/icons/icon-calendar-disabled.svg') 9px 7px no-repeat;
      }
    }
  }

  .quantity-control__input {
    height: 38px;
  }

  .info-block__price {
    @include breakpoint(medium) {
      margin-right: $s2-5;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .text-field__input {
      padding-left: 27px;
    }
  }

  &__info-value--quote-number {
    display: flex;
    height: 50%;
    align-items: center;
  }

  .info-block__price,
  .info-block__valid-until {
    .text-field__input-group {
      position: relative;
    }

    .currency-symbol,
    img {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 8px;
      left: 10px;
    }

    .currency-symbol {
      font-size: 20px;
      line-height: 24px;
      color: $red;
      top: 10px;
    }
  }

  .info-block__valid-until {
    .text-field__input {
      padding-left: 37px;
    }
  }

  .error-notification {
    @include df-p2-bold;
    border-radius: 4px;
    align-items: flex-start;

    &__icon {
      width: 24px;
      display: flex;
    }

    p {
      padding-top: $s0-5;
    }
  }

  &__short-supply-warning {
    margin-bottom: $s2;
  }

  .textarea-field {
    border-radius: 8px;

    &--error {
      border-color: $red;
    }
  }

  .select-field__menu-list {
    max-height: 240px;
  }

  .info-label-valid-until,
  .info-label-price {
    .custom-tooltip__content {
      width: auto;
    }
  }

  &__quantity-notifications {
    margin-bottom: $s2-5;
  }

  &__quantity-notification {
    margin-bottom: $s0-5;
  }

  &__icon-notice {
    width: 20px;
    margin-right: 9px;
  }

  &__message,
  &__content-wrapper {
    padding: 0 $s2-5 $s2-5 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4 $s4 $s4;
    }
  }

  &__modal-footer {
    flex-wrap: wrap;
  }

  &__date-input {
    @include df-p2-medium;
    border: 1px solid $input-gray;
    border-radius: 8px;
    color: $black;
    height: 40px;
  }

  &__month {
    margin-bottom: $s2-5;
    text-transform: capitalize;

    @include breakpoint(medium) {
      width: 40%;
      margin-bottom: 0;
    }
  }

  &__day-and-year {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      width: 58%;
    }
  }

  &__day {
    width: 40%;
  }

  &__year {
    width: 55%;
  }

  &__date {
    &-day {
      @include df-p2-medium;
      color: $black;

      &:hover {
        width: 24px;
        height: 24px;
      }

      &[data-weekend] {
        color: $black;
      }

      &[data-selected],
      &[data-selected]:hover {
        width: 24px;
        height: 24px;
        color: $white;
        margin: 0 auto;
      }

      &[data-disabled] {
        font-size: 11px;
      }
    }

    &-calendar-header-level {
      font: 18px/1.33 $museo-700;
      color: $brown;

      &-arrows {
        color: $dark-gray;
      }
    }

    &-weekday {
      @include df-p2-bold;
      color: $brown;
    }

    &-month {
      width: 100%;
    }

    &-month {
      text-align: center;
    }

    &-calendar-header {
      @include breakpoint(small down) {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      @include breakpoint(medium) {
        max-width: 100%;
      }
    }

    &-input {
      text-transform: capitalize;
    }

    &-select-label {
      @include df-p4-bold;
      margin-bottom: $s1;
      letter-spacing: 1.2px;
      color: $dark-gray;
    }
  }
}

@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.add-new-bank-account-modal-eft-pad-modal {
  $add-new-bank-account-modal-eft-pad-modal: &;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &--with-loading-indicator {
    &.modal__content {
      min-height: 300px;

      @include breakpoint(medium) {
        min-height: 500px;
      }
    }
  }

  &__wrapper {
    padding: 0 $s2 $s3-5;
    margin-bottom: $s10;
    overflow: auto;

    @include breakpoint(medium) {
      padding: 0 $s4 $s3-5;
    }
  }

  &__field {
    margin-top: $s2-5;
    @include breakpoint(medium) {
      margin-top: $s1-5;
    }
  }

  &__description {
    @include df-p3-medium;
    padding-top: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__contact-name {
    @include breakpoint(medium) {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      #{$add-new-bank-account-modal-eft-pad-modal}__field {
        width: 100%;

        &:first-child {
          margin-right: $s1-5;
        }
      }
    }
  }

  &__account-dropdown {
    height: 48px;

    .select__control {
      height: 100%;
    }

    &-options {
      display: flex;
      flex-direction: column-reverse;
      margin: $s2-5 0 22px;

      @include breakpoint(medium) {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin: $s1-5 0 0;
      }
    }
  }

  &__state-label {
    margin-bottom: $s1;

    @include breakpoint(medium) {
      padding-left: $s1-5;
    }

    &-error {
      color: $red;
    }
  }

  &__state-block,
  &__account-holder-type-block {
    margin: $s2-5 0 $s1 0;

    @include breakpoint(medium) {
      margin: 0;
    }
  }

  &__header {
    margin-bottom: 0;
  }

  .text-field__input::placeholder {
    color: $input-gray;
    font-style: italic;
    font-size: 14px;
  }

  .select__placeholder {
    @include df-p1-medium;
    color: $black;
  }
  .error-notification {
    margin-top: $s1;
  }

  &__footer {
    padding: $s2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: 80px;

    @include breakpoint(medium) {
      padding: $s2 $s4;
    }

    .modal-footer {
      &__cancel-button.cor-button.text {
        @include df-p1-medium;
        line-height: 19.2px;
        letter-spacing: 0.2px;
      }

      &__cancel-button.cor-button--is-international {
        .content {
          text-decoration: underline;
        }
      }
    }
  }

  &__required-fields-hint {
    @include df-p3-italic;
    margin-top: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-italic;
      margin-top: $s1-5;
    }

    #{$add-new-bank-account-modal-eft-pad-modal}__required-field {
      margin-right: $s0-5;
    }
  }

  &__required-field {
    color: $red;
  }

  &__city-field {
    padding-right: 10px;

    @include breakpoint(medium) {
      padding-right: $s1-5;
    }
  }

  &__country-block {
    align-items: baseline;

    #{$add-new-bank-account-modal-eft-pad-modal}__field {
      .text-field__input {
        color: $achromatic-gray;
      }
    }
    #{$add-new-bank-account-modal-eft-pad-modal}__state-dropdown {
      .select__control {
        height: 48px;
      }

      & > div {
        height: 100%;
      }

      .select__menu-list {
        max-height: 109px;
      }

      @include breakpoint(medium) {
        padding-left: $s1-5;
      }
    }
  }

  &__account-block {
    align-items: baseline;

    #{$add-new-bank-account-modal-eft-pad-modal}__account-dropdown {
      @include breakpoint(medium) {
        padding-left: 6px;
      }

      & > div {
        height: 100%;
      }
    }

    #{$add-new-bank-account-modal-eft-pad-modal}__field {
      position: relative;
      @include breakpoint(medium) {
        padding-right: 6px;
      }
    }
  }

  &__field {
    &-icon {
      position: absolute;
      right: 13px;
      top: 42px;

      @include breakpoint(medium) {
        right: 20px;
      }
    }
  }

  &__transit-number-field {
    position: relative;
  }

  &__account-type-label {
    margin-bottom: $s1;

    @include breakpoint(medium) {
      padding-left: $s1;
    }

    &-error {
      color: $red;
    }
  }

  &__state-dropdown.select--is-error,
  &__account-dropdown.select--is-error {
    height: fit-content;

    #{$add-new-bank-account-modal-eft-pad-modal}__state-label,
    #{$add-new-bank-account-modal-eft-pad-modal}__account-type-label {
      color: $red;
    }

    .select__control {
      height: 48px;
    }

    &-message {
      height: fit-content;
    }
  }

  &__collapsible-section {
    margin-top: $s4;

    @include breakpoint(medium) {
      margin-top: $s1;
    }
  }
}

@import 'styles/_foundation';
@import 'styles/_variables';

$sliderIconDesktopSize: 88px;
$sliderIconMobileSize: 56px;
$sliderIconImageDesktopSize: 64px;
$sliderIconImageMobileSize: 40px;

.order-updates {
  position: relative;

  @include breakpoint(medium) {
    padding: $s3 0 $s5;
  }

  &__notifications-section {
    @include breakpoint(medium) {
      padding-left: $s3;
    }

    &--standard {
      @include breakpoint(medium) {
        padding-top: 69px;
      }
    }

    &--with-cutoff {
      .next-order-message,
      .order-pickup-message {
        margin-top: 0;
        background-color: $white;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-expired {
        .next-order-message,
        .order-pickup-message {
          border-color: $red;

          .next-order-message__due-date,
          .order-pickup-message__title span {
            color: $red;
          }
        }
      }
    }

    &--with-pickup-notifications {
      padding-top: 0;

      @include breakpoint(medium) {
        padding-left: $s3;
      }
      .order-pickup-message {
        margin-top: 0;
      }
    }

    &--with-pickup-notification ~ &:not(.order-updates__notification-section--without-news) {
      @include breakpoint(medium) {
        margin-top: -5px;
        padding-left: $s3;
      }
    }

    &--without-news {
      display: flex;
      flex-direction: column;
      padding-top: 54px;

      @include breakpoint(medium) {
        padding-top: 68px;
        padding-left: $s3;
      }

      .order-cutoff-notification--home-page {
        margin-top: 0;
      }

      .order-cutoff-notification--home-page .order-cutoff-notification__context {
        padding-bottom: $s1-5;
      }
    }

    &--with-pickup-notifications ~ &--without-news ~ &:not(.order-updates__notification-section--with-cutoff-expired) {
      @include breakpoint(medium) {
        padding-top: $s9;
        padding-left: 0;
      }
    }

    &--without-news ~ &--with-cutoff-expired ~ &:not(.order-updates__notification-section--with-pickup-notifications) {
      @include breakpoint(medium) {
        padding-top: 68px;
        padding-left: $s3;
      }
    }
  }

  &--with-cutoff {
    .next-order-message,
    .order-pickup-message {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: $white;
    }

    &-expired {
      .next-order-message,
      .order-pickup-message {
        border-color: $red;

        .next-order-message__due-date,
        .order-pickup-message__title span,
        strong {
          font-weight: bold;
          color: $red;
        }
      }
    }
  }

  .next-order-message,
  .order-pickup-message {
    display: flex;
    justify-content: space-between;
  }

  .next-order-message {
    &:not(.next-order-message--full-size) {
      margin-bottom: 11px;
    }

    @include breakpoint(medium) {
      &:not(.next-order-message--full-size) {
        margin-bottom: $s3;
      }
    }
  }

  .order-pickup-message {
    &:not(.order-pickup-message--full-size) {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      &:not(.order-pickup-message--full-size) {
        margin-bottom: $s3;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  .order-updates__inner-orders {
    position: relative;

    &--narrow {
      width: 100%;

      @include breakpoint(medium) {
        width: 60.5%;
      }

      .recent-orders {
        @include breakpoint(medium) {
          padding-right: $s4;
        }
      }

      .recent-order-info {
        margin-bottom: 17px;

        @include breakpoint(medium) {
          margin-bottom: $s6;
        }
      }

      .recent-orders-slider {
        @include breakpoint(medium) {
          padding: 0 $s5;
        }
      }

      .recent-order-info-section {
        @include breakpoint(medium) {
          margin: -8px 15px;
        }

        &__icon {
          width: $sliderIconMobileSize;
          height: $sliderIconMobileSize;

          @include breakpoint(medium) {
            width: $sliderIconDesktopSize;
            height: $sliderIconDesktopSize;
          }

          img {
            width: $sliderIconImageMobileSize;
            height: $sliderIconImageMobileSize;

            @include breakpoint(medium) {
              width: $sliderIconImageDesktopSize;
              height: $sliderIconImageDesktopSize;
            }
          }
        }

        &__info {
          flex-direction: column;
          margin: $s1 $s0-5;

          @include breakpoint(medium) {
            margin: $s1 13px;
          }
        }
      }
    }
  }

  .tabs {
    &__tab {
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }

    @include breakpoint(medium) {
      border-bottom: none;
    }
  }

  &-wrapper > .grid-container {
    @include breakpoint(small down) {
      padding: 0;
    }
  }

  &__loading-indicator-wrapper {
    position: relative;
    min-height: 400px;
    background-color: $white;
    border-radius: 4px;
    border: none;

    @include breakpoint(medium) {
      border: 1px solid $input-gray;
      margin-right: $s3;
    }
  }
}

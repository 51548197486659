@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.discontinued-items-with-replacements-modal {
  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 500px;
    }

    .custom-tooltip__content {
      width: auto;
      margin-left: -35px;

      &:after,
      &:before {
        right: 8px;
        left: auto;
      }
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__description {
    @include df-p1-medium;
    margin-bottom: $s0-5;
  }

  &__button {
    display: flex;
    justify-content: flex-end;

    button {
      width: 100%;

      @include breakpoint(medium) {
        width: auto;
        min-width: 224px;
      }
    }
  }

  .modal-footer {
    margin-top: 0;
    padding: 10px 28px;

    &__close-button {
      margin-top: $s1-5;
    }

    button {
      @include breakpoint(medium) {
        margin-top: $s3;
      }
    }
  }
}

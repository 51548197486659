@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.shortpay-reasons-modal {
  $shortpay-reasons-modal: &;

  overflow: visible;

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__description {
    @include df-p2-medium;
    margin: $s2-5 0;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin: $s1 0 $s3 0;
    }
  }

  &__reasons-dropdown {
    margin-bottom: $s6;

    @include breakpoint(medium) {
      margin-bottom: $s4;
    }

    .select {
      &__menu-list {
        z-index: 61;
        max-height: 207px;

        @include breakpoint(medium) {
          padding-top: $s1;
        }

        .select__option {
          @include df-p1-medium;
        }
      }

      &__single-value {
        @include df-p1-bold;
        padding: 10px 0;
      }
    }
  }

  &__reasons-dropdown.select {
    .select__control {
      padding-left: $s1-5;
    }
  }

  &__reasons-dropdown.select--is-error {
    margin-bottom: 0;

    #{$shortpay-reasons-modal}__description {
      color: $red;
      margin-bottom: 10px;
    }

    &-message {
      margin: 10px 0;
    }
  }

  .modal-footer {
    @include breakpoint(medium) {
      flex-direction: row-reverse;

      #{$shortpay-reasons-modal}__submit-button {
        width: 140px;
        margin-right: $s3;
      }
    }

    &__cancel-button {
      .content {
        @include df-p1-medium;
      }
    }
  }
}

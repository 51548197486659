@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.add-new-bank-account-modal {
  $add-new-bank-account-modal: &;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &--with-loading-indicator {
    &.modal__content {
      min-height: 300px;

      @include breakpoint(medium) {
        min-height: 500px;
      }
    }
  }

  &__header {
    margin-bottom: 0;
  }

  &__wrapper {
    padding: 0 $s2 $s3-5;
    margin-bottom: $s10;
    overflow: auto;

    @include breakpoint(medium) {
      padding: 0 $s4 $s3-5;
    }
  }

  &__field {
    margin-top: $s2-5;
    @include breakpoint(medium) {
      margin-top: $s1-5;
    }
  }

  &__description {
    @include df-p3-medium;
    padding-top: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__contact-name {
    @include breakpoint(medium) {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      #{$add-new-bank-account-modal}__field {
        width: 100%;

        &:first-child {
          margin-right: $s1-5;
        }
      }
    }
  }

  &__account-dropdown {
    height: 48px;

    &-holder.select--is-error,
    &-type.select--is-error {
      #{$add-new-bank-account-modal}__account-holder-type-label,
      #{$add-new-bank-account-modal}__account-type-label {
        color: $red;
      }
    }

    .select__control {
      height: 100%;
    }

    &-options {
      display: flex;
      flex-direction: column-reverse;
      margin: $s2-5 0 22px;

      @include breakpoint(medium) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 22px 0 0;
      }
    }
  }

  &__account-type-label,
  &__account-holder-type-label {
    margin-bottom: $s1;
  }

  &__account-holder-type-block {
    margin-top: $s2-5;
    @include breakpoint(medium) {
      margin-top: 0;
    }
  }

  &__account-holder-type-block,
  &__account-type-block {
    @include breakpoint(medium) {
      width: 100%;

      &:first-child {
        margin-right: $s1-5;
      }
    }
  }

  .text-field__input::placeholder {
    color: $input-gray;
    font-style: italic;
    font-size: 14px;
  }

  .select__placeholder {
    @include df-p1-medium;
    color: $black;
  }
  .error-notification {
    margin-top: $s1;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: 80px;

    .modal-footer {
      &__cancel-button.cor-button.text {
        @include df-p1-medium;
        line-height: 19.2px;
        letter-spacing: 0.2px;
      }

      &__cancel-button.cor-button--is-international {
        .content {
          text-decoration: underline;
        }
      }
    }
  }

  &__required-fields-hint {
    @include df-p3-italic;
    margin-top: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-italic;
      margin-top: $s1-5;
    }

    #{$add-new-bank-account-modal}__required-field {
      margin-right: $s0-5;
    }
  }

  &__required-field {
    color: $red;
  }
}

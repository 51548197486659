@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.make-deposit-modal {
  $make-deposit-modal: &;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 500px;
    }
  }

  &__wrapper {
    padding: 0 $s2 $s0-5;
    overflow: auto;
    margin-bottom: 80px;

    @include breakpoint(medium) {
      padding: 0 $s4 $s3;
      margin-bottom: 86px;
    }
  }

  &__field {
    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(medium) {
      &:first-child {
        margin-top: $s3;
      }
    }

    .text-field__label {
      margin-bottom: $s1;
    }

    textarea {
      resize: none;
    }

    textarea,
    input {
      font: 16px/1.5 $frutiger-55-roman;
    }
  }

  &__description {
    @include df-p3-medium;
    padding-top: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__amount-filed {
    margin-top: 22px;

    .text-field__input-adornment {
      @include df-p2-italic;
      color: $achromatic-gray;
      padding-top: 2px;
    }

    .text-field__input {
      padding-left: 30px;

      &::placeholder {
        @include df-p2-italic;
        line-height: 20px;
      }
    }
  }

  &__comments-filed {
    margin: $s3 0;
  }

  @include breakpoint(medium) {
    margin: $s3 0 $s2-5 0;
  }

  &__credit-card-surcharge {
    @include df-caption;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1.2px;
    margin-top: $s2-5;

    @include breakpoint(medium) {
      margin-top: $s3;
    }

    span {
      color: $red;
    }
  }

  .text-field__input::placeholder {
    color: $achromatic-gray;
    font-style: italic;
    font-size: 14px;
  }

  .select__placeholder {
    @include df-p1-medium;
    color: $black;
  }
  .error-notification {
    margin-top: $s1;
  }

  &__header {
    margin-bottom: 0;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: 80px;

    @include breakpoint(medium) {
      margin: 0;
      height: 86px;
    }

    .modal-footer {
      height: 100%;
      align-items: center;
    }

    .cor-button.modal-footer__cancel-button {
      @include df-p1-medium;
      letter-spacing: 0.2px;
      line-height: 19.2px;
    }

    .cor-button.modal-footer__save-button {
      height: 48px;
    }
  }

  &__required-field {
    color: $red;
  }
}
